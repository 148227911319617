<template>
  <div>
    <h2>
      <span v-if="!page">Pages</span>
      <span
        v-else
        class="d-flex justify-space-between"
      >
        <span>{{ page.href }}</span>
        <a @click="aside = true">Edit</a>
      </span>
    </h2>

    <v-list
      class="resource-list"
      @mouseover.native="$emit('focus', '')"
      @click.native="$emit('focus', '')"
    >
      <v-list-item
        v-for="item in tableItems"
        :key="`${item.id}${item.updated_at}`"
        class="resource"
        :class="{
          'v-list-item--active': page && page.id === item.id
        }"
        @click="toPage(item)"
      >
        <v-list-item-content>
          <v-list-item-title>
            <v-icon
              v-if="item.href.includes('/')"
              size="30"
            >
              {{ icons.mdiWeb }}
            </v-icon>

            {{ item.href }}
          </v-list-item-title>
          <p class="text--secondary mt-1">
            {{ item.notes }}
          </p>
        </v-list-item-content>
      </v-list-item>
    </v-list>

    <site-pages-aside
      v-if="aside"
      v-model="aside"
      :resource="page"
      @changed="loadData(); aside = false"
    />
  </div>
</template>

<script>
import SitePagesAside from '@/modules/site/views/site-pages/site-pages-resource/SitePagesAside.vue'
import router from '@/router'
import { mdiWeb } from '@mdi/js'
import { ref } from '@vue/composition-api'
import useSitePages from '../site-pages/useSitePages'

export default {
  components: { SitePagesAside },
  props: {
    site: {
      type: Object,
      required: true,
    },
    page: {
      type: Object,
      default: null,
    },
  },

  setup(props, { emit }) {
    const {
      loadData, tableItems, filters, options,
    } = useSitePages()

    filters.site_id = props.site.id
    options.itemsPerPage = 999

    loadData().then(() => {
      let defaultItem = tableItems.value[0]
      const pageId = parseInt(router.currentRoute.query.page_id)

      if (pageId) {
        defaultItem = tableItems.value.find(p => p.id === pageId) || defaultItem
      }

      defaultItem && toPage(defaultItem)
    })

    const toPage = page => {
      emit('click-page', page)

      if (props.page && page.id !== props.page.id) {
        router.replace({ query: { page_id: page.id } })
      }
    }

    return {
      aside: ref(false),
      toPage,
      tableItems,
      icons: {
        mdiWeb,
      },
    }
  },
}
</script>

<style scoped>
.resource-list {
  max-height: 70vh;
  overflow-y: scroll;
}
.resource + .resource {
  border-top: 1px solid #e3e3e3;
}
</style>
