<template>
  <v-list-item class="cursor-pointer">
    <v-list-item-content>
      <v-list-item-title>
        <v-badge
          color="secondary"
          :content="item.sort || '0'"
          inline
        ></v-badge>

        <v-icon
          v-if="item.type === 'css'"
          size="30"
        >
          {{ icons.mdiCodeBraces }}
        </v-icon>

        <v-icon
          v-if="item.type === 'image'"
          size="30"
        >
          {{ icons.mdiImage }}
        </v-icon>

        <v-icon
          v-if="item.type === 'link'"
          size="30"
        >
          {{ icons.mdiLink }}
        </v-icon>

        <v-icon
          v-if="['title', 'content'].includes(item.type)"
          size="30"
        >
          {{ icons.mdiText }}
        </v-icon>

        {{ item.key }}

        <v-chip
          v-if="!item.active"
          color="error"
        >
          Disabled
        </v-chip>

        <v-chip
          v-if="item.feature"
          color="info"
        >
          {{ item.feature.key }}
        </v-chip>

        <v-chip
          v-if="item.guest && (!item.auth || item.auth_state)"
        >
          GUEST
        </v-chip>
        <v-chip
          v-if="item.auth && (!item.guest || item.auth_state)"
          color="warning"
        >
          AUTH
          <span v-if="item.auth_state">:{{ item.auth_state }}</span>
        </v-chip>
      </v-list-item-title>

      <div class="text--secondary mt-1">
        <span
          v-if="item.notes"
          class="ml-2"
        >{{ item.notes }}</span>
        <span
          v-if="item.element.text"
          class="ml-2"
        >{{ t(item.element.text) }}</span>
        <b
          v-if="item.element.href"
          class="ml-2"
        >({{ item.element.href }})</b>
        <span
          v-if="item.element.content"
          class="ml-2"
        >({{ item.element.content }})</span>
      </div>
    </v-list-item-content>

    <v-list-item-avatar
      v-if="getImageSrc(item)"
      size="38"
    >
      <v-img
        :src="getImageSrc(item)"
        :style="{ maxWidth: '100%', maxHeight: '100px'}"
      >
      </v-img>
    </v-list-item-avatar>
  </v-list-item>
</template>

<script>
import { t } from '@/plugins/i18n'
import {
mdiCodeBraces, mdiImage, mdiLink, mdiText,
} from '@mdi/js'
import useSiteItem from '../site-items/useSiteItem'

export default {
  props: {
    item: {
      type: Object,
      required: true,
    },
  },

  setup() {
    const {
      getImageSrc,
    } = useSiteItem()

    return {
      getImageSrc,
      t,

      icons: {
        mdiCodeBraces, mdiImage, mdiLink, mdiText,
      },
    }
  },
}
</script>
