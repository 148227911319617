<template>
  <div>
    <h4>
      <span v-if="!item">Items</span>
      <span
        v-else
        class="d-flex justify-space-between"
      >
        <span>{{ item.key }}</span>
        <a @click="aside = true">Edit</a>
      </span>
    </h4>

    <v-list
      class="resource-list"
      @mouseover.native="$emit('focus', 'item')"
      @click.native="$emit('focus', 'item')"
    >
      <template v-for="record in tableItems">
        <SiteViewItem
          :key="`${record.id}-${record.updated_at}`"
          class="resource"
          :class="{
            'v-list-item--active': item && item.id === record.id
          }"
          :item="record"
          @click.native="$emit('click-item', record); aside = true"
        />

        <SiteViewItem
          v-for="child in record.children"
          :key="`${child.id}-${child.updated_at}`"
          class="resource pl-8"
          :class="{
            'v-list-item--active': item && item.id === child.id
          }"
          :item="child"
          @click.native="$emit('click-item', child); aside = true"
        />
      </template>

      <v-list-item class="resource">
        <ReplicateSiteItem
          v-if="canReplicateItem(section.key)"
          :items="tableItems"
          @created="reload"
        />
      </v-list-item>
    </v-list>

    <site-items-aside
      v-if="aside"
      v-model="aside"
      :resource="item"
      @changed="reload(); aside = false"
    />
  </div>
</template>

<script>
import SiteItemsAside from '@/modules/site/views/site-items/site-items-resource/SiteItemsAside.vue'
import { t } from '@/plugins/i18n'
import { ref } from '@vue/composition-api'
import ReplicateSiteItem from '../site-items/site-items-resource/ReplicateSiteItem.vue'
import useSiteItem from '../site-items/useSiteItem'
import useSiteItems from '../site-items/useSiteItems'
import SiteViewItem from './SiteViewItem.vue'

export default {
  components: { SiteItemsAside, ReplicateSiteItem, SiteViewItem },
  props: {
    section: {
      type: Object,
      required: true,
    },
    item: {
      type: Object,
      default: null,
    },
  },

  setup(props, { emit }) {
    const {
      loadData, tableItems, filters, options,
    } = useSiteItems({ root_only: true })

    const {
      getImageSrc,
    } = useSiteItem()

    options.itemsPerItem = 999

    const reload = () => {
      loadData().then(() => {
        const firstItem = tableItems.value[0]

        firstItem && emit('click-item', firstItem)
      })
    }

    const init = () => {
      filters.section_id = props.section.id
      tableItems.value = []

      return reload()
    }

    // watch(computed(() => props.section), init)

    init()

    return {
      getImageSrc,

      imgSrc: '',
      aside: ref(false),
      tableItems,
      t,

      reload,

      canReplicateItem(sectionKey) {
        const allowedKeys = [
          'app_splash',
          'home_carousel',
          'nav_user',
          'product_selection',
          'our_partners',
          'rewards_and_recognition',
          'special_offer',
        ]

        return allowedKeys.some(key => sectionKey.includes(key))
      },
    }
  },
}
</script>

<style scoped>
.resource-list {
    max-height: 70vh;
    overflow-y: scroll;
}
.resource + .resource {
    border-top: 1px solid #e3e3e3;
}
</style>
