<template>
  <v-form ref="formElem">
    <v-card>
      <v-card-text>
        <v-select
          v-model="form.site_id"
          :items="siteOptions"
          item-text="title"
          item-value="value"
          label="Choose Site"
          outlined
          hide-details
          disabled
        ></v-select>

        <v-text-field
          v-model="form.href"
          label="href"
          outlined
          :rules="[required]"
          class="mt-5"
          :disabled="isReserved"
        />
        <p v-if="isReserved">
          You cannot change reserved page's URL.
        </p>
      </v-card-text>

      <v-card-actions class="justify-center">
        <v-btn
          color="primary"
          :loading="loading"
          @click="validate"
        >
          Submit
        </v-btn>
      </v-card-actions>

      <v-alert
        v-for="msg in errors"
        :key="msg"
        color="warning"
      >
        {{ msg }}
      </v-alert>
    </v-card>
  </v-form>
</template>

<script>
import router from '@/router'
import { required } from '@core/utils/validation'
import { } from '@mdi/js'
import { ref } from '@vue/composition-api'
import useSites from '../../useSites'

export default {
  components: {},

  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    disabledFields: {
      type: Array,
      default() {
        return []
      },
    },
    errors: {
      type: Array,
      default() {
        return []
      },
    },
    resource: {
      // default resource
      type: [Object, Boolean],
      default: false,
    },
  },

  setup(props, { emit }) {
    // Get value from resource prop, use initValue if not exist
    const { siteOptions, loadSites } = useSites()
    loadSites()

    const field = (name, initValue) => ({
      [name]: props.resource ? props.resource[name] : initValue,
    })
    const siteIdField = (name, initValue) => ({
      [name]: props.resource ? props.resource[name] : router.currentRoute.params.site_id ? parseInt(router.currentRoute.params.site_id) : initValue,
    })

    const rules = props.resource ? props.resource.rules : false

    const initialForm = {
      ...siteIdField('site_id', null),
      ...field('href', null),
    }

    const form = ref({
      ...initialForm,
    })
    const formElem = ref(null)

    const validate = () => {
      if (formElem.value.validate()) emit('submit', form.value)
    }

    return {
      form,
      formElem,
      validate,
      required,

      isReserved: props.resource && props.resource.reserved,
      siteOptions,
    }
  },
}
</script>
