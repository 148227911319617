<template>
  <div>
    <h3>
      <span v-if="!section">Sections</span>
      <span
        v-else
        class="d-flex justify-space-between"
      >
        <span>{{ section.key }}</span>
        <a
          class="ml-2"
          @click="aside = true"
        >Edit</a>
      </span>
    </h3>

    <v-list
      class="resource-list"
      @mouseover.native="$emit('focus', 'section')"
      @click.native="$emit('focus', 'section')"
    >
      <v-list-item
        v-for="item in tableItems"
        :key="`${item.id}${item.updated_at}`"
        class="resource"
        :class="{
          'v-list-item--active': section && section.id === item.id
        }"
        @click="toSection(item)"
      >
        <v-list-item-content>
          <v-list-item-title>
            <v-badge
              color="secondary"
              :content="item.sort || '0'"
              inline
            ></v-badge>

            <v-icon
              v-if="item.type === 'plain'"
              size="30"
            >
              {{ icons.mdiText }}
            </v-icon>

            <v-icon
              v-if="item.type === 'carousel'"
              size="30"
            >
              {{ icons.mdiImageMultiple }}
            </v-icon>

            <v-icon
              v-if="item.type === 'component'"
              size="30"
            >
              {{ icons.mdiPuzzle }}
            </v-icon>

            {{ item.key }}
            <v-chip
              v-if="item.guest && (!item.auth || item.auth_state)"
            >
              GUEST
            </v-chip>
            <v-chip
              v-if="item.auth && (!item.guest || item.auth_state)"
              color="warning"
            >
              AUTH
              <span v-if="item.auth_state">:{{ item.auth_state }}</span>
            </v-chip>
            <v-chip
              v-if="!item.active"
              color="error"
            >
              Disabled
            </v-chip>
          </v-list-item-title>
          <div
            class="text--secondary mt-1"
          >
            <v-chip
              v-if="item.selection"
              color="info"
              @click="goToSelection(item.selection)"
            >
              <v-icon>
                {{ icons.mdiGestureTap }}
              </v-icon>
              {{ item.selection.key }}
            </v-chip>

            <v-chip
              v-if="item.feature"
              color="info"
            >
              {{ item.feature.key }}
            </v-chip>
            <p v-if="item.notes">
              {{ item.notes }}
            </p>
          </div>
        </v-list-item-content>
      </v-list-item>

      <v-list-item
        v-if="section"
        class="resource"
      >
        <ReplicateSection
          v-if="section.key.includes('faq_')"
          :items="tableItems"
          @created="loadData"
        />
      </v-list-item>
    </v-list>

    <site-sections-aside
      v-if="aside"
      v-model="aside"
      :resource="section"
      @changed="loadData(); aside = false"
    />
  </div>
</template>

<script>
import SiteSectionsAside from '@/modules/site/views/site-sections/site-sections-resource/SiteSectionsAside.vue'
import router from '@/router'
import {
mdiGestureTap,
mdiImageMultiple, mdiPuzzle, mdiText,
} from '@mdi/js'
import { computed, ref } from '@vue/composition-api'
import ReplicateSection from '../site-sections/site-sections-resource/ReplicateSection.vue'
import useSiteSections from '../site-sections/useSiteSections'

export default {
  components: {
    SiteSectionsAside,
    ReplicateSection,
  },
  props: {
    page: {
      type: Object,
      required: true,
    },
    section: {
      type: Object,
      default: null,
    },
  },

  setup(props, { emit }) {
    const {
      loadData, tableItems, filters, options,
    } = useSiteSections()

    options.itemsPerSection = 999

    const querySectionId = computed(() => parseInt(router.currentRoute.query.section_id))

    const toSection = section => {
      emit('click-section', section)

      if (querySectionId.value !== section.id) router.replace({ query: { ...router.currentRoute.query, section_id: section.id } })
    }

    const init = () => {
      filters.page_id = props.page.id
      loadData().then(() => {
        let defaultItem = tableItems.value[0]

        if (querySectionId.value) {
          defaultItem = tableItems.value.find(p => p.id === querySectionId.value) || defaultItem
        }

        toSection(defaultItem)
      })
    }

    // watch(computed(() => props.page), init)

    init()

    return {
      aside: ref(false),
      tableItems,
      toSection,
      loadData,

      goToSelection(selection) {
        setTimeout(() => this.$router.push(`/selections/view/${selection.id}`), 100)
      },
      icons: {
        mdiText,
        mdiGestureTap,
        mdiImageMultiple,
        mdiPuzzle,
      },
    }
  },
}
</script>

  <style scoped>
  .resource-list {
    max-height: 70vh;
    overflow-y: scroll;
  }
  .resource + .resource {
    border-top: 1px solid #e3e3e3;
  }
  </style>
